<template>
  <router-view />
</template>

<script setup>
</script>

<style>
/* 你可以在这里添加全局样式 */

.beian {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px;
  background-color: #f5f5f5;
  color: #666;
  font-size: 12px;
  text-decoration: none;
  z-index: 999;
  background-color: transparent;
}

</style>
