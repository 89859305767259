<template>
    <div class="background-container">
      <div class="content-container">
        <div class="container py-4">
          <div class="card main-card">
            <div class="card-body">
              <h1 class="card-title text-center mb-4">软研工作室</h1>
              <p class="text-center text-muted mb-4">IOS定制个人证书</p>
              
              <div class="mb-4">
                <label for="udid" class="form-label">设备UDID</label>
                <div class="input-group mb-2">
                  <input type="text" class="form-control" id="udid" v-model="udid" readonly>
                  <button class="btn btn-outline-primary" @click="getUDID">获取UDID</button>
                </div>
              </div>
              
              <div class="row g-2 mb-4">
                <div class="col-6">
                  <button class="btn btn-secondary w-100" @click="copyUDID">复制UDID</button>
                </div>
                <div class="col-6">
                  <a :href="buyPermissionUrl" class="btn btn-success w-100" target="_blank">购买权限</a>
                </div>
              </div>
              
              <div class="mb-4">
                <button class="btn btn-primary w-100" @click="submitForm">兑换</button>
              </div>
              
              <div class="card mb-4">
                <div class="card-body">
                  <h5 class="card-title">使用说明：</h5>
                  <ol class="list-group list-group-flush">
                    <li class="list-group-item">1. "获取UDID"按钮获取您的设备ID。</li>
                    <li class="list-group-item">2. "复制UDID"按钮并发给客服。</li>
                    <li class="list-group-item">3. 如需购买，请点击"购买权限"。</li>
                    <li class="list-group-item">4. 点击"兑换"按钮完成操作。</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a class="beian" href="https://beian.mps.gov.cn/" rel="noreferrer" target="_blank">粤ICP备2024290050号-1</a>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import cookie from 'js-cookie'
  
  const udid = ref('')
  const router = useRouter()
  const store = useStore()
  const buyPermissionUrl = 'https://s.tb.cn/c.0EgOLC'
  
  onMounted(() => {
    const params = new URLSearchParams(window.location.search)
    udid.value = params.get('UDID') || localStorage.getItem('udid') || ''  
    if (udid.value) {
      store.commit('setUDID', udid.value)
      cookie.set('udid', udid.value, { expires: 7 })
    } else {
      udid.value = cookie.get('udid') || ''
    }
  })
  
  const getUDID = () => {
    window.location.href = 'https://cert.rcodeanalysis.icu/static/UDID.mobileconfig'
  }
  
  
  const copyUDID = () => {
    // 使用 clipboard API 方法
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(udid.value).then(() => {
        alert('UDID已复制到剪贴板');
      }).catch((err) => {
        console.error('无法复制UDID: ', err);
        alert('复制失败，请手动复制UDID');
      });
    } else {
      // 使用 textarea 方法
      const textArea = document.createElement('textarea');
      textArea.value = udid.value;
      // 设置样式让它不可见
      textArea.style.position = 'fixed'; // 避免滚动影响
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = '0';
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';

      document.body.appendChild(textArea);
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          alert('UDID已复制到剪贴板');
        } else {
          alert('复制失败，请手动复制UDID');
        }
      } catch (err) {
        console.error('无法复制UDID: ', err);
        alert('复制失败，请手动复制UDID');
      }

      document.body.removeChild(textArea);
    }
  };
  
  const submitForm = async () => {
    try {
      const response = await fetch('/api/process_udid/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          udid: udid.value,
        })
      });
  
      const data = await response.json();
  
      if (data.success) {
        alert(data.message);
        router.push({ name: 'SoftwarePage', params: { udid: udid.value } })
      } else {
        console.log(data.error);
        alert(`操作失败：${data.error}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('发生错误，请稍后再试。' + error);
    }   
  }
  </script>
  
  <style scoped>
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  .background-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('@/assets/background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
  }
  
  .content-container {
    position: relative;
    min-height: 100vh;
    width: 100%;
    overflow-y: auto;
    padding: 20px 0;
  }
  
  .main-card {
    background-color: rgba(255, 255, 255, 0.9);
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .btn {
    font-weight: bold;
  }
  
  .card, .list-group-item {
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .list-group-item {
    border-color: rgba(0, 0, 0, 0.125);
  }
  </style>