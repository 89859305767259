import { createStore } from 'vuex'

export default createStore({
  state: {
    // 定义全局状态变量
    udid: '',
  },
  mutations: {
    // 定义同步方法
    setUDID(state, udid) {
      state.udid = udid
    },
  },
    actions: {
            // 定义异步方法
            asyncSetUdid({ commit }, udid) {
            commit('setUDID', udid)
        },
    },
    modules: {
        // 定义模块
    }
})
