<template>
  <div class="vh-100 d-flex align-items-center justify-content-center bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card shadow-lg">
            <h1 class="card-title mb-4">{{ props.appName }}</h1>
            <div class="card-body text-center p-5">
              <div class="text-center">
                <img 
                  :src="croppedImage || props.iconSrc" 
                  :alt="appName + ' Icon'" 
                  class="mb-2 shadow-sm rounded" 
                  style="width: 100px; height: 100px; cursor: pointer;" 
                  @click="openCustomizationModal"
                >
                <p class="text-muted" style="font-size: 0.9em; cursor: pointer;" @click="openCustomizationModal">点我可换图标</p>
              </div>
              <input type="text" v-model="customDesktopName" class="form-control card-title-input mb-4" placeholder="微信【点此更名】">
              <input 
                type="text" 
                v-model="customBid" 
                v-show="isTestMode"
                class="form-control card-title-input mb-4"  
                :disabled="props.bid === 'com.tencent.xin'"
              >

              <div v-if="!isSigningInProgress" class="mb-4">
                <button class="btn btn-primary btn-lg w-100 mb-3" @click="signIPA">
                  点击安装
                </button>
                <a :href="usageTutorialUrl" class="btn btn-outline-info w-100 mt-2" target="_blank">使用教程</a>
              </div>

              <div v-if="isSigningInProgress" class="progress-container mb-4">
                <svg class="progress-ring" width="150" height="150">
                  <circle class="progress-ring__circle" stroke="#e9ecef" stroke-width="8" fill="transparent" r="67"
                    cx="75" cy="75" />
                  <circle class="progress-ring__circle progress-ring__circle--progress" stroke="#007bff"
                    stroke-width="8" fill="transparent" r="67" cx="75" cy="75" :stroke-dasharray="circumference"
                    :stroke-dashoffset="dashOffset" />
                </svg>
                <div class="progress-text">{{ progressPercentage }}%</div>
              </div>

              <p v-if="isSigningInProgress" class="text-muted">
                正在进行签名，请稍候...
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 图片裁剪的模态框 -->
  <div v-if="showModal" class="modal fade show d-block" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">更换图标</h5>
          <button type="button" class="btn-close" @click="showModal = false"></button>
        </div>
        <div class="modal-body text-center">
          <input type="file" @change="handleImageUpload" accept="image/*">
          <div v-if="imageFile">
            <img :src="imageFile" id="cropper-image" class="img-fluid mt-3">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="showModal = false">取消</button>
          <button type="button" class="btn btn-primary" @click="cropImage">裁剪并上传</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref, onMounted, computed, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import cookie from 'js-cookie'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'

const router = useRouter()
const store = useStore()
const udid = ref('')
const isSigningInProgress = ref(false)
const progressPercentage = ref(0)
const circumference = 2 * Math.PI * 67
const dashOffset = computed(() => {
  return circumference - (progressPercentage.value / 100) * circumference
})

const usageTutorialUrl = 'https://www.yuque.com/xianweiguanfangqijiandian/dd66cn/sl7mt2rolskuuwg2?singleDoc#'

/* eslint-disable no-undef */
const props = defineProps({
  appName: {
    type: String,
    required: true
  },
  bid: {
    type: String,
    required: true
  },
  iconSrc: {
    type: String,
    required: true
  },
  id: {
    type: String,
    required: true
  }
})

const customDesktopName = ref('')
const customBid = ref(props.bid || ''); // 仅在组件首次渲染时初始化
const customIcon = ref(null)
const isTestMode = ref(false)

const showModal = ref(false)
const imageFile = ref(null)
const croppedImage = ref(null)
const isSigning = ref(false); 
let cropper = null

onMounted(() => {
  udid.value = store.state.udid ? store.state.udid : cookie.get('udid')
  if (!udid.value) {
    alert('未找到UDID，请重新获取。')
    router.push({ name: 'UDIDForm' })
  }
  getTestMode().then((result) => {
    isTestMode.value = result
  })
})

const openCustomizationModal = () => {
  if (!isSigning.value) {
    showModal.value = true;
  }
}

const handleImageUpload = (event) => {
  if (isSigning.value) return; // 如果正在签名，阻止上传操作
  const file = event.target.files[0];
  if (file) {
    imageFile.value = URL.createObjectURL(file);
    nextTick(() => {
      initCropper();
    });
  }
}

const initCropper = () => {
  const image = document.getElementById('cropper-image')
  if (cropper) cropper.destroy()
  cropper = new Cropper(image, {
    aspectRatio: 1, // 保持正方形裁剪框
    viewMode: 1,    // 限制裁剪框在图片范围内
    autoCropArea: 1, // 默认裁剪区域覆盖整个图片
    movable: true,  // 允许移动裁剪框
    zoomable: true, // 允许放大缩小图片
    scalable: true, // 允许图片缩放
    cropBoxResizable: true, // 允许调整裁剪框大小
  })
}

const cropImage = () => {
  if (isSigning.value) return; // 如果正在签名，阻止裁剪操作
  croppedImage.value = cropper.getCroppedCanvas().toDataURL('image/png');
  customIcon.value = croppedImage.value;
  showModal.value = false;
  cropper.destroy();
}


const simulateProgress = () => {
  let totalTime = 80000  // 60 秒
  let elapsedTime = 0
  let lastIncrease = Date.now()

  const interval = setInterval(() => {
    const now = Date.now()
    elapsedTime += now - lastIncrease
    lastIncrease = now

    if (elapsedTime >= totalTime || progressPercentage.value >= 99) {
      clearInterval(interval)
      return
    }

    const theoreticalProgress = (elapsedTime / totalTime) * 100
    const randomFactor = Math.random() * 5 - 2.5 
    const maxProgress = Math.min(theoreticalProgress + 5, 99)
    const newProgress = Math.max(
      progressPercentage.value,
      Math.min(theoreticalProgress + randomFactor, maxProgress)
    )

    progressPercentage.value = Math.round(newProgress)

  }, 1000) 
}

const getTestMode = async () => {
  // 向服务器get请求，传入udid
    const response = await fetch(`/api/get_test_mode/${udid.value}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const data = await response.json();
    if (data.success) {
      return true;
    } 
    return false;
}

const check_signing_status = async (udid, version, task_id) => {
  try {
    const response = await fetch(`/api/check_signing_status/${udid}/${version}/${task_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.success) {
      if (data.plist_url) {
        progressPercentage.value = 100;
        alert('签名成功，开始安装。');
        setTimeout(() => {
          window.location.href = `itms-services://?action=download-manifest&url=${encodeURIComponent(data.plist_url)}`
        }, 1000);
      } else {
        alert('签名成功，但未收到有效的安装链接。');
      }
    } else if (data.status === 'processing') {
      setTimeout(() => check_signing_status(udid, props.id, task_id), 5000);
    } else {
      alert('签名失败或未找到签名进程。');
      isSigningInProgress.value = false;
    }
  } catch (error) {
    console.error('Error:', error);
    alert('发生错误，请稍后再试。' + error);
    isSigningInProgress.value = false;
  }
};

const isValidName = (name) => /^[\u4e00-\u9fa5a-zA-Z0-9\s._-]+$/.test(name);

const signIPA = async () => {
  try {
    isSigning.value = true; // 开始签名，禁用图片交互
    isSigningInProgress.value = true;
    progressPercentage.value = 0;
    simulateProgress();

    const appInfo = { id: props.id };

    if (customDesktopName.value) {
      if (isValidName(customDesktopName.value)) {
        appInfo.name = customDesktopName.value;
      } else {
        alert('无效的应用名称，请检查输入。');
        isSigningInProgress.value = false;
        isSigning.value = false;
        return;
      }
    }

    if (customIcon.value) appInfo.icon = customIcon.value;
    if (customBid.value) appInfo.bid = customBid.value;

    console.log('customBid:', customBid.value);

    const requestData = {
      udid: udid.value,
      app_info: appInfo
    };

    const response = await fetch('/api/sign_ipa/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.success) {
      const task_id = data.task_id;
      check_signing_status(udid.value, props.id, task_id);
    } else {
      alert(`签名失败：${data.error}`);
      isSigningInProgress.value = false;
      isSigning.value = false;
    }
  } catch (error) {
    console.error('Error:', error);
    alert('发生错误，请稍后再试。' + error);
    isSigningInProgress.value = false;
    isSigning.value = false;
  }
};

</script>

<style scoped>
.progress-container {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
}

.progress-ring__circle {
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  font-weight: bold;
  color: #007bff;
}

.bg-light {
  background-color: #f8f9fa;
}

.card {
  max-width: 400px;
  margin: 0 auto;
}

.card-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  position: relative;
  top: 30px;
}

.card-title-input {
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  outline: none;
}

.card-title-input::placeholder {
  color: #6c757d;
}
</style>