// src/router.js
import { createRouter, createWebHistory } from 'vue-router'
import UDIDForm from './components/UDIDForm.vue'
import SignPage from './components/SignPage.vue' // 假设 SignPage 也是一个组件
import SoftwarePage from './components/SignatureList.vue'

const routes = [
  { path: '/', name: 'UDIDForm', component: UDIDForm },
  // { path: '/sign', name: 'SignPage', component: SignPage }, 
  // 写一个软件选择页面，然后跳转到 SignPage
  { path: '/software', name: 'SoftwarePage', component: SoftwarePage },
  {
    path: '/sign/:id',
    name: 'SignPage',
    component: SignPage,
    props: route => ({
      appName: route.query.appName,
      iconSrc: route.query.iconSrc,
      bid: route.query.bid,
      id: route.params.id,
    })
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
